<template>
    <v-container id="view-payments" tag="section">
      <base-loader :isLoading="isSecondaryLoading"></base-loader>
      <base-dialog-notification ref="viewAdminPaymentsConfirm" />
      <base-snackbar-notification ref="viewAdminPaymentsSnackbar" />
      <base-material-card
        color="default"
        icon="mdi-currency-usd"
        inline
        class="px-5 py-3"
        :elevation="8"
      >
        <v-container>
          <v-data-table
            v-model="selectedPayments"
            :headers="headers"
            :items="formattedPayments"
            show-select
            item-key="id"
            loading-text="Loading... Please wait"
            :loading="isLoading"
            :search="search"
            :sort-by.sync="sortBy"
          >
            <template v-slot:top>
              <v-row justify="center">
                <v-col md="4">
                  <v-text-field v-model="search" label="Search" outlined dense />
                </v-col>
                <v-col md="2"></v-col>
                <v-col md="6">
                  <v-btn color="primary" @click="createBatch()" class="float-right" dense elevation="0" :disabled="hasSelectedPayments">
                    Process Payment
                  </v-btn>
                </v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col
                    cols="4"
                    sm="6"
                    md="4"
                >
                  <v-menu
                      ref="menu"
                      v-model="dateMenu"
                      :close-on-content-click="false"
                      :return-value.sync="filteredDateRange"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="dateRangeText"
                          label="Date Range"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          outlined
                          dense
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="filteredDateRange"
                        range
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                          text
                          color="primary"
                          @click="dateMenu = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                          text
                          color="primary"
                          @click="$refs.menu.save(filteredDateRange)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col md="4">
                    <v-select
                        v-model="filteredStatus"
                        :items="filteredStatuses"
                        label="Payment Statuses"
                        multiple
                        outlined
                        dense
                        hint="Select Statuses to view"
                    >
                    </v-select>
                </v-col>
                <v-col md="4">
                  <v-switch
                      v-model="showEmployerNotPaid"
                      label="Show Employer Not Paid"
                      class="mt-1"
                  ></v-switch>
                </v-col>
              </v-row>
              <v-dialog
                  v-model="dialogView" max-width="700px">
                <v-card>
                  <v-toolbar dark color="primary">
                    <v-btn
                        icon
                        dark
                        @click="dialogView = false"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>Payment Details</v-toolbar-title>
                  </v-toolbar>
                  <template v-if="dialogView">
                    <v-card-text class="px-2">
                      <v-container>
                        <v-row>
                          <v-col cols="12 pb-0 d-flex justify-end" >
                            <span
                                v-text="$moment(selectedPayment.createdAt).format('HH:mm A, dddd, DD MMM YYYY')"
                                class="text-content"
                            ></span>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12"><span class="text-h5">Period: {{$moment(selectedPayment.workDaysStartDate).format('DD MMM')}} - {{$moment(selectedPayment.workDaysEndDate).format('DD MMM')}}</span></v-col>
                        </v-row>
                        <v-divider class="my-2"></v-divider>
                        <v-row>
                          <v-col cols="2"><v-spacer></v-spacer></v-col>
                          <v-col cols="10" class="text-right"><span class="text-h5 font-weight-black mr-2">TOTAL WORK PAY:</span><span class="mr-2 text-h5 font-weight-black">{{ returnCurrencyFormattedNumber(selectedPayment.totalWorkPay, selectedPayment.currencyCode) }}</span></v-col>
                        </v-row>
                        <template>
                          <v-row>
                            <v-col cols="4"><span class="text-h5">Deductions</span></v-col>
                          </v-row>
                          <v-divider class="my-2"></v-divider>
                          <v-row v-if="selectedPayment.deductions.length > 0" class="mb-0">
                            <v-col cols="12" class="ma-0 pa-0">
                              <v-list color="white" dense class="mt-2">
                                <v-list-item v-for="(deduction, index) in selectedPayment.deductions" :key="index">
                                  <v-list-item-icon class="mr-3" v-if="!deduction.new">
                                    <v-icon color="warning">mdi-cash-remove</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-content>
                                    <v-list-item-title class="text-capitalize font-weight-bold mr-3" v-text="deduction.description"></v-list-item-title>
                                  </v-list-item-content>
                                  <v-list-item-action-text>
                                    <v-list-item-title class="font-weight-black">{{ returnCurrencyFormattedNumber(deduction.amount, selectedPayment.currencyCode) }}</v-list-item-title>
                                  </v-list-item-action-text>
                                </v-list-item>
                              </v-list>
                            </v-col>
                          </v-row>
                          <v-row v-else>
                            <v-col cols="12"><span class="text-body-2">(None)</span></v-col>
                          </v-row>
                          <template v-if="selectedPayment.deductions.length > 0">
                            <v-divider class="my-2"></v-divider>
                            <v-row>
                              <v-col cols="6"><v-spacer></v-spacer></v-col>
                              <v-col cols="6" class="text-right"><span class="text-h5 font-weight-black mr-2">TOTAL:</span><span class="mr-2 text-h5 font-weight-black">- {{ totalFormattedDeductions(selectedPayment.deductions, selectedPayment.currencyCode) }}</span></v-col>
                            </v-row>
                          </template>
                          <template v-else>
                            <v-row  class="my-4">
                            </v-row>
                          </template>
                          <template>
                            <v-row class="mt-6">
                              <v-col cols="6" class="py-0 my-0"><span class="text-body-1 font-weight-medium">{{serviceFeeWording(selectedPayment.serviceFeeAmount, selectedPayment.serviceFeeType)}}</span></v-col>
                              <v-col cols="2" class="py-0 my-0"><v-spacer></v-spacer></v-col>
                              <v-col cols="4" class="py-0 my-0 text-right"><span class="mr-2 text-body-1 font-weight-medium">+ {{ totalServiceFee(selectedPayment.employeePaymentAmount, totalDeductions(selectedPayment.deductions), selectedPayment.serviceFeeAmount, selectedPayment.serviceFeeType, selectedPayment.currencyCode) }}</span></v-col>
                            </v-row>
                          </template>
                          <template>
                            <v-row class="mt-1">
                              <v-col cols="6"><span class="text-h5 font-weight-black">TOTAL EMPLOYER PAYMENT</span></v-col>
                              <v-col cols="6" class="text-right"><span class="mr-2 text-h5 font-weight-black">{{ returnCurrencyFormattedNumber(selectedPayment.employerPaymentAmount, selectedPayment.currencyCode) }}</span></v-col>
                            </v-row>
                            <v-divider class="my-2"></v-divider>
                          </template>
                          <template>
                            <v-row class="mt-1">
                              <v-col cols="6"><span class="text-h5">Employee Payment Details:</span></v-col>
                            </v-row>
                            <v-row class="mt-0">
                              <v-col cols="5"><span class="text-body-2 font-weight-black">Name:</span></v-col>
                              <v-col cols="7"><span class="text-body-2">{{ selectedPayment.employeeAccountHolder }}</span></v-col>
                            </v-row>
                            <v-row class="mt-0">
                              <v-col cols="5"><span class="text-body-2 font-weight-black">Acc. Number:</span></v-col>
                              <v-col cols="7"><span class="text-body-2">{{ selectedPayment.employeeAccountNumber }}</span></v-col>
                            </v-row>
                            <v-row class="mt-0">
                              <v-col cols="5"><span class="text-body-2 font-weight-black">Bank:</span></v-col>
                              <v-col cols="7"><span class="text-body-2">{{ selectedPayment.employeeBankName }}</span></v-col>
                            </v-row>
                            <v-row class="mt-0">
                              <v-col cols="5"><span class="text-body-2 font-weight-black">Branch Code:</span></v-col>
                              <v-col cols="7"><span class="text-body-2">{{ selectedPayment.employeeBranchCode }}</span></v-col>
                            </v-row>
                            <v-row class="mt-0">
                              <v-col cols="5"><span class="text-body-2 font-weight-black">Amount:</span></v-col>
                              <v-col cols="7"><span class="text-body-2">{{ returnCurrencyFormattedNumber(selectedPayment.employeePaymentAmount, selectedPayment.currencyCode) }}</span></v-col>
                            </v-row>
                            <template v-if="selectedPayment.status === 'Paid'">
                            <v-row class="mt-0">
                              <v-col cols="5"><span class="text-body-2 font-weight-black">Payment Date:</span></v-col>
                              <v-col cols="7"><span class="text-body-2">{{ $moment(selectedPayment.employeePaymentDate).format('HH:mm A, dddd, DD MMM YYYY') }}</span></v-col>
                            </v-row>
                            </template>
                            <v-row class="mt-0">
                              <v-col cols="5"><span class="text-body-2 font-weight-black">Payment Status:</span></v-col>
                              <v-col cols="7"><span class="text-body-2">{{ selectedPayment.status }}</span></v-col>
                            </v-row>
                            <v-row class="mt-0">
                              <v-col cols="5"><span class="text-body-2 font-weight-black">Payment Batch Reference:</span></v-col>
                              <v-col cols="7"><span class="text-body-2">{{ selectedPayment.employeePaymentReference }}</span></v-col>
                            </v-row>
                            <template v-if="selectedPayment.status === 'Failed' || selectedPayment.status === 'Declined'">
                              <v-row class="mt-0">
                                <v-col cols="5"><span class="text-body-2 font-weight-black">Payment Failure Reason:</span></v-col>
                                <v-col cols="7"><span class="text-body-2">{{ selectedPayment.employeePaymentFailureReason }}</span></v-col>
                              </v-row>
                            </template>
                            <v-row class="">
                            </v-row>
                            <v-divider class="my-4"></v-divider>
                          </template>
                          <template>
                            <v-row class="mt-0">
                              <v-col cols="6" class="pt-0"><span class="text-h5">Employer Payment Details:</span></v-col>
                            </v-row>
                            <v-row class="mt-0">
                              <v-col cols="5"><span class="text-body-2 font-weight-black">Date:</span></v-col>
                              <v-col cols="7"><span class="text-body-2">{{ $moment(selectedPayment.employerPaymentDate).format('HH:mm A, dddd, DD MMM YYYY')}}</span></v-col>
                            </v-row>
                            <v-row class="mt-0">
                              <v-col cols="5"><span class="text-body-2 font-weight-black">Status:</span></v-col>
                              <v-col cols="7"><span class="text-body-2">{{ formatPaymentStatus(selectedPayment.employerPaymentStatus) }}</span></v-col>
                            </v-row>
                            <v-row class="mt-0" v-if="!selectedPayment.employerPaymentAccepted">
                              <v-col cols="5"><span class="text-body-2 font-weight-black">Reason:</span></v-col>
                              <v-col cols="7"><span class="text-body-2">{{ selectedPayment.employerPaymentFailureReason }}</span></v-col>
                            </v-row>
                            <v-row class="mt-0">
                              <v-col cols="5"><span class="text-body-2 font-weight-black">Method:</span></v-col>
                              <v-col cols="7"><span class="text-body-2">{{ selectedPayment.employerPaymentMethod }}</span></v-col>
                            </v-row>
                            <v-row class="mt-0">
                              <v-col cols="5"><span class="text-body-2 font-weight-black">Reference:</span></v-col>
                              <v-col cols="7"><span class="text-body-2">{{ selectedPayment.employerPaymentReference }}</span></v-col>
                            </v-row>

                          </template>
                        </template>
                      </v-container>
                    </v-card-text>
                  </template>
                </v-card>
              </v-dialog>
            </template>
            <template v-slot:item.employerPaymentAccepted="{ item }">
              <div v-if="item.employerPaymentAccepted">
                <v-icon dense class="mr-2" color="success">
                  mdi-check
                </v-icon>
              </div>
              <div v-else>
                <v-icon sense class="mr-2" color="error">
                  mdi-close
                </v-icon>
            </div>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon
                  dense
                  class="mr-2"
                  color="primary"
                  @click="viewItem(item)"
              >
                mdi-eye
              </v-icon>
            </template>
          </v-data-table>
        </v-container>
      </base-material-card>
    </v-container>
  </template>
  
  <script>
  import { gql } from "apollo-boost";
  import PaymentCreate from "../../../components/Base/PaymentCreate";
  import { saveAs } from 'file-saver';

  import { readFileSync, writeFileSync } from 'fs'
  import { tmpdir } from 'os'

  
  // import defaultProfile from "@/assets/avatar.png";
  export default {
    name: "ViewPayments",
    components: {
      PaymentCreate,
    },
    data() {
      return {
        isSecondaryLoading: false,
        statusQuery: "",
        fields: {
          "Employer": "employerName",
          "Employee": "employeeName",
          "Paid For": "period",
          "Pay by": "processBy",
          "Status": "status",
          "Amount": "amount",
          "Created At": "createdAt",
        },
        headers: [
          {
            text: "Payment Date",
            value: "employeeFormattedPaymentDate",
            align: "left",
          },
          {
            text: "Employer",
            value: "employerName",
            align: "left",
          },
  
          {
            text: "Employee",
            value: "employeeName",
            align: "left",
          },
          {
            text: "Period",
            value: "period",
            align: "left",
          },
          {
            text: "Amount",
            value: "employeeFormattedPaymentAmount",
            align: "left",
          },
          {
            text: "Employer Paid",
            value: "employerPaymentAccepted",
            align: "left",
            filterable: true,
          },
          {
            text: "Status",
            value: "status",
            align: "left",
          },
          {
            text: "Actions",
            value: "actions",
            filterable: false,
            sortable: false,
          },
        ],
        dialog: false,
        search: "",
        sortBy: "employeeFormattedPaymentDate",
        showEmployerNotPaid: false,
        filteredDateRange: [this.$moment().subtract(1, 'day').startOf('day').format('YYYY-MM-DD'), this.$moment().endOf('day').format('YYYY-MM-DD')],
        dateMenu: false,
        filteredStatuses: ['Ready for payment', 'Sending to Netcash', 'Sent to Netcash', 'Failed',  'Paid'],
        filteredStatus: ['Ready for payment'],
        payments: [],
        dialogView: false,
        viewItemValue: {},
        viewItemIndex: null,
        selectedPayment: {},
        selectedPaymentIndex: null,
        formattedPayments: [],
        selectedPayments: [],
        totalRecords: 0,
        isLoading: false,
        options: {
          itemsPerPage: 10,
          page: 1,
        },
      };
    },
    apollo: {
      me: gql`
        query {
          me {
            id
            email
            firstName
            lastName
            role
          }
        }
      `,
      payments: {
        query: gql`
          query Payments($limit: Int!, $skip: Int!, $query: JSON!) {
            payments(limit: $limit, skip: $skip, query: $query) {
              records {
                id
                employerId
                employerFirstName
                employerLastName
                employerPaymentDate
                employerPaymentAmount
                employerPaymentReference
                employerPaymentStatus
                employerPaymentAccepted
                employerPaymentFailureReason
                employerPaymentRequestTrace
                employerPaymentMethod
                employerPaymentCcToken
                employerPaymentCcHolder
                employerPaymentCcMasked
                employerPaymentCcExpiry
                employeeId
                employeeFirstName
                employeeLastName
                employeeAccountNumber
                employeeAccountType
                employeeAccountHolder
                employeeBankName
                employeeBranchCode
                employeeRequestedPaymentDate
                employeePaymentDate
                employeePaymentReference
                employeePaymentBatchId
                employeePaymentStatus
                employeePaymentFailureReason
                employeePaymentAmount
                deductions {
                  id
                  description
                  amount
                }
                totalWorkPay
                wageRate
                wagePeriod
                wageTracking
                workDaysStartDate
                workDaysEndDate
                currencyCode
                serviceFeeAmount
                serviceFeeType
                createdAt
                createdBy
                updatedAt
                updatedBy
                deleted
                deletedAt
                deletedBy
              }
              count
            }
          }
        `,
        fetchPolicy: "no-cache",
        variables() {
          return {
            limit: 9999,
            skip: 0,
            query: this.paymentsQuery,
          };
        },
        watchLoading(isLoading, countModifier) {
          this.isLoading = isLoading;
        },
        result(data, key) {
          if (data.error) {
            console.error('Error getting payments.')
            this.$refs.viewAdminPaymentsConfirm.open("Error", "There was a problem while retrieving payments. Please try again or contact support.", "error")
            return []
          } else {
            this.formattedPayments = this.formatPaymentRecords(data.data.payments.records)
          }
        },
      },
    },
    computed: {
      paymentsQuery() {
        let query = {
          employerPaymentStatus: null,
          employeePaymentStatus: null,
          employeeRequestedPaymentDate: null,
        }
        if (this.showEmployerNotPaid) {
          query.employerPaymentStatus = { $in: ['PENDING', 'FAILED', 'DECLINED', null] }
        } else {
          query.employerPaymentStatus = { $eq: 'PAID' }
        }
        query.employeePaymentStatus = this.returnEmployeePaymentStatusQuery(this.filteredStatus)
        query.employeeRequestedPaymentDate = this.returnDateRangeQuery(this.filteredDateRange)
        if (!query.employeeRequestedPaymentDate) delete query.employeeRequestedPaymentDate
        return query
      },
      dateRangeText () {
        if (this.$moment(this.filteredDateRange[0]).isAfter(this.$moment(this.filteredDateRange[1]))) {
          const firstDate = this.filteredDateRange[1]
          this.filteredDateRange[1] = this.filteredDateRange[0]
          this.filteredDateRange[0] = firstDate
        }
        let dateText = `${this.$moment(this.filteredDateRange[0]).format('DD/MM/YYYY')} - ${this.$moment(this.filteredDateRange[1]).format('DD/MM/YYYY')}`
        return dateText
      },
      hasSelectedPayments () {
        return !this.selectedPayments.length > 0
      },
    },
    watch: {},
    methods: {
      refreshData() {
        this.$apollo.queries.payments.refetch();
      },
      formatPaymentRecords (payments) {
        return this.$_.map(payments, (payment) => {
          return {
            id: payment.id,
            employerId: payment.employerId,
            employerName: payment.employerFirstName + " " + payment.employerLastName,
            employerFirstName: payment.employerFirstName,
            employerLastName: payment.employerLastName,
            employerPaymentDate: payment.employerPaymentDate,
            employerPaymentAmount: payment.employerPaymentAmount,
            employerPaymentReference: payment.employerPaymentReference,
            employerPaymentStatus: payment.employerPaymentStatus,
            employerPaymentAccepted: payment.employerPaymentAccepted,
            employerPaymentFailureReason: payment.employerPaymentFailureReason,
            employerPaymentRequestTrace: payment.employerPaymentRequestTrace,
            employerPaymentMethod: payment.employerPaymentMethod,
            employerPaymentCcToken: payment.employerPaymentCcToken,
            employerPaymentCcHolder: payment.employerPaymentCcHolder,
            employerPaymentCcMasked: payment.employerPaymentCcMasked,
            employerPaymentCcExpiry: payment.employerPaymentCcExpiry,
            employeeId: payment.employeeId,
            employeeName: payment.employeeFirstName + " " + payment.employeeLastName,
            employeeFirstName: payment.employeeFirstName,
            employeeLastName: payment.employeeLastName,
            employeeAccountNumber: payment.employeeAccountNumber,
            employeeAccountType: payment.employeeAccountType,
            employeeAccountHolder: payment.employeeAccountHolder,
            employeeBankName: payment.employeeBankName,
            employeeBranchCode: payment.employeeBranchCode,
            employeeRequestedPaymentDate: payment.employeeRequestedPaymentDate,
            employeePaymentDate: payment.employeePaymentDate,
            employeeFormattedPaymentDate: this.$moment(payment.employeePaymentDate).format('DD/MM/YYYY'),
            employeePaymentReference: payment.employeePaymentReference,
            employeePaymentBatchId: payment.employeePaymentBatchId,
            employeePaymentStatus: payment.employeePaymentStatus,
            employeePaymentFailureReason: payment.employeePaymentFailureReason,
            employeePaymentAmount: payment.employeePaymentAmount,
            employeeFormattedPaymentAmount: this.returnCurrencyFormattedNumber(payment.employeePaymentAmount, payment.currencyCode),
            deductions: payment.deductions,
            totalWorkPay: payment.totalWorkPay,
            wageRate: payment.wageRate,
            wagePeriod: payment.wagePeriod,
            wageTracking: payment.wageTracking,
            workDaysStartDate: payment.workDaysStartDate,
            workDaysEndDate: payment.workDaysEndDate,
            currencyCode: payment.currencyCode,
            serviceFeeAmount: payment.serviceFeeAmount,
            serviceFeeType: payment.serviceFeeType,
            createdAt: payment.createdAt,
            createdBy: payment.createdBy,
            updatedAt: payment.updatedAt,
            updatedBy: payment.updatedBy,
            deleted: payment.deleted,
            deletedAt: payment.deletedAt,
            deletedBy: payment.deletedBy,
            period: `${this.$moment(payment.workDaysStartDate).format('D MMM')} - ${this.$moment(payment.workDaysEndDate).format('D MMM')}`,
            status: this.returnFriendlyPaymentStatus(payment.employeePaymentStatus)
          }
        })
      },
      returnCurrencyFormattedNumber (number, currency) {
        if (number == null || number === 'undefined') return 'R 0.00'
        const roundedNumber = this.$_.round(number, 2)
        if (!currency) return `R ${roundedNumber}`
        const formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: currency,
          currencyDisplay: 'narrowSymbol'
        })
        return formatter.format(roundedNumber)
      },
      returnFriendlyPaymentStatus (status) {
        switch(status) {
          case 'QUEUED':
            return 'Ready for payment'
          case 'PENDING':
            return 'Sending to Netcash'
          case 'UPLOADED':
            return 'Sent to Netcash'
          case 'FAILED':
            return 'Failed'
          case 'DECLINED':
            return 'Declined'
          case 'PAID':
            return 'Paid'
          default:
            return status
        }
      },
      returnEmployeePaymentStatusQuery (statuses) {
        const inStatus = []
        const paymentStatus = { $in: inStatus }

        this.$_.forEach(statuses, (status) => {
          const paymentStatusName = this.returnPaymentStatusFromFriendly(status)
          if(paymentStatusName) inStatus.push(paymentStatusName)
        })

        return paymentStatus
      },
      returnPaymentStatusFromFriendly (status) {
        switch(status) {
          case 'Ready for payment':
            return 'QUEUED'
          case 'Sending to Netcash':
            return 'PENDING'
          case 'Sent to Netcash':
            return 'UPLOADED'
          case 'Failed':
            return 'FAILED'
          case 'Paid':
            return 'PAID'
          default:
            return status
        }
      },
      returnDateRangeQuery (dateRange) {
        const dateRangeQuery = {}
        if (dateRange && dateRange.length > 0) {
          dateRangeQuery.$gte = this.$moment(dateRange[0]).startOf('day').format('x')
          dateRangeQuery.$lte = this.$moment(dateRange[1]).endOf('day').format('x')
        } else {
          return null
        }
        return dateRangeQuery
      },
      viewItem (item) {
        this.selectedPaymentIndex = this.formattedPayments.indexOf(item)
        this.selectedPayment = Object.assign({}, item)
        this.dialogView = true
      },
      closeViewDialog () {
        this.dialogView = false
        this.$nextTick(() => {
          this.selectedPayment = null
          this.selectedPaymentIndex = -1
        })
      },
      formatPaymentStatus(status) {
        if(status) return this.$_.capitalize(status)
        return 'Unknown'
      },
      totalDeductions (deductions) {
        let totalDeductionsAmount = 0
        if(this.$_.size(deductions) > 0) {
          this.$_.forEach(deductions, (deduction) => {
            totalDeductionsAmount += this.$_.toNumber(deduction.amount)
          })
        }
        return this.$_.round(totalDeductionsAmount, 2)
      },
      totalFormattedDeductions (deductions, currency) {
        let totalDeductionsAmount = 0
        if(this.$_.size(deductions) > 0) {
          this.$_.forEach(deductions, (deduction) => {
            totalDeductionsAmount += this.$_.toNumber(deduction.amount)
          })
        }
        const formattedNumber = this.returnCurrencyFormattedNumber(totalDeductionsAmount, currency)
        return formattedNumber
      },
      totalServiceFee (totalWork, totalDeductions, serviceFee, serviceFeeType, currency) {
        if(!totalDeductions) totalDeductions = 0
        if (serviceFeeType === 'percentage') {
          const totalPay = totalWork - totalDeductions
          const calcServiceFee = totalPay * (serviceFee / 100)
          const roundedTotal = this.returnCurrencyFormattedNumber(calcServiceFee, currency)
          return roundedTotal
        } else {
          return this.returnCurrencyFormattedNumber(serviceFee, currency)
        }
      },
      serviceFeeWording (serviceFee, serviceFeeType) {
        if (serviceFeeType === 'percentage') {
          return `Service Fee (${serviceFee}%)`
        } else {
          return 'Service Fee'
        }
      },
      customFilter(value, search, item) {
        return (
          value != null &&
          search != null &&
          typeof value === "string" &&
          value.toString().indexOf(search) !== -1
        );
      },
      defaultProfileImage() {
        return defaultProfile;
      },
      async createBatch() {
        const self = this;
        const paymentIds = this.$_.map(this.selectedPayments, 'id')
        await self.$apollo.mutate({
          mutation: gql`
          mutation CreateSalaryBatch($payments: CreateSalaryBatchInput!) {
              createSalaryBatch(payments: $payments) {
                response
                success
              }
            }
          `,
          variables: {
            payments: {
              paymentIds: paymentIds,
            },
          },
        }).then((response) => {
          if (response.data.createSalaryBatch.success == true) {
            this.$refs.viewAdminPaymentsConfirm.open("Success", "Payments have been submitted to Netcash for processing.", "success")
            this.selectedPayments = []
            this.refreshData()
          } else {
            this.$refs.viewAdminPaymentsConfirm.open("Error", "There was a problem while processing the payments. Please try again or contact support.", "error")
          }
        }).catch((error) => {
          console.error('error: ', error)
          this.$refs.viewAdminPaymentsConfirm.open("Error", `There was a problem while processing the payments. ${error}`, "error")
        })
      }
    },
  }
  </script>
  <style lang="sass"></style>
  